<template>
    <b-card no-body>
        <b-card-header class="align-items-baseline">
            <div>
                <b-card-title class="mb-25">
                    حجم معاملات
                </b-card-title>
                <!--                <b-card-text class="mb-0">-->
                <!--                    مجموع روزانه : {{[100000].toLocaleString()}}-->
                <!--                </b-card-text>-->
            </div>
        </b-card-header>

        <b-card-body class="pb-0">

            <!-- apex chart -->
            <vue-apex-charts
                    type="line"
                    height="240"
                    :options="salesLine.chartOptions"
                    :series="salesLine.series"
            />
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    // BCardText,
    // BButton,
    // BButtonGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'

export default {
    name: 'feeLineChart',
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        // BCardText,
        BCardTitle,
        BCardBody,
        // BButton,
        // BButtonGroup,
    },
    props: ['chart', 'type'],
    data() {
        return {
            salesLine: {
                series: [
                    {
                        name: ' - تومان ',
                        data: [0, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
                    },

                ],
                chartOptions: {
                    chart: {
                        toolbar: {show: false},
                        zoom: {enabled: false},
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            top: 18,
                            left: 2,
                            blur: 5,
                            opacity: 0.2,
                        },
                        offsetX: -10,
                        fontFamily: 'Yekan'
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 4,
                    },
                    grid: {
                        borderColor: '#ebe9f1',
                        padding: {
                            top: -20,
                            bottom: 5,
                            left: 20,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    colors: ['#f2ed87'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            inverseColors: false,
                            gradientToColors: [$themeColors.warning],
                            shadeIntensity: 1,
                            type: 'horizontal',
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100],
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            size: 5,
                        },
                    },
                    xaxis: {
                        labels: {
                            offsetY: 5,
                            style: {
                                colors: '#b9b9c3',
                                fontSize: '0.857rem',
                                fontFamily: 'Yekan'
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        categories: [],
                        axisBorder: {
                            show: false,
                        },
                        tickPlacement: 'on',
                    },
                    yaxis: {
                        tickAmount: 5,
                        labels: {
                            style: {
                                colors: '#b9b9c3',
                                fontSize: '0.857rem',
                                fontFamily: 'Yekan'
                            },
                        },
                    },
                    tooltip: {
                        x: {show: false},
                    },
                },
            },
        }
    },
    watch: {
        'chart'(val) {
            let value = val.map(i => i.value)
            this.salesLine.series = [{
                name: ' - تومان ' ,
                data: value,
            }]
            this.salesLine.chartOptions.xaxis.categories = []
            this.$forceUpdate()
        }
    },
    mounted() {
        this.salesLine.series = [{
            name: ' - واحد ',
            data: this.chart,
        }]
    }
}
</script>
