<template>
    <b-card no-body>
        <b-card-header class="align-items-baseline">
            <div>
                <b-card-title class="mb-25">
                    تعداد تراکنش ها
                </b-card-title>
                <b-card-text class="mb-0">
                    مجموع روزانه : {{[100000].toLocaleString()}}
                </b-card-text>
            </div>
        </b-card-header>

        <b-card-body class="pb-0">

            <!-- apex chart -->
            <vue-apex-charts
                    type="line"
                    height="240"
                    :options="salesLine.chartOptions"
                    :series="salesLine.series"
            />
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BCardText,
        // BButton,
        // BButtonGroup,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {$themeColors} from '@themeConfig'

    export default {
        name:'LineChart2',
        components: {
            VueApexCharts,
            BCard,
            BCardHeader,
            BCardText,
            BCardTitle,
            BCardBody,
            // BButton,
            // BButtonGroup,
        },
        data() {
            return {
                filterBy: 'month',
                salesLine: {
                    series: [
                        {
                            name: 'کل',
                            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
                        },
                        {
                            name: 'برپایه تتر',
                            data: [0.2 * 140, 0.2 * 180, 0.2 * 150, 0.2 * 205, 0.2 * 160, 0.2 * 295, 0.2 * 125, 0.2 * 255, 0.2 * 205, 0.2 * 305, 0.2 * 240, 0.2 * 295],
                        },

                    ],
                    chartOptions: {
                        chart: {
                            toolbar: {show: false},
                            zoom: {enabled: false},
                            type: 'line',
                            dropShadow: {
                                enabled: true,
                                top: 18,
                                left: 2,
                                blur: 5,
                                opacity: 0.2,
                            },
                            offsetX: -10,
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 4,
                        },
                        grid: {
                            borderColor: '#ebe9f1',
                            padding: {
                                top: -20,
                                bottom: 5,
                                left: 20,
                            },
                        },
                        legend: {
                            show: false,
                        },
                        colors: ['#c087f2'],
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shade: 'dark',
                                inverseColors: false,
                                gradientToColors: [$themeColors.primary],
                                shadeIntensity: 1,
                                type: 'horizontal',
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100, 100, 100],
                            },
                        },
                        markers: {
                            size: 0,
                            hover: {
                                size: 5,
                            },
                        },
                        xaxis: {
                            labels: {
                                offsetY: 5,
                                style: {
                                    colors: '#b9b9c3',
                                    fontSize: '0.857rem',
                                    fontFamily: 'Yekan'
                                },
                            },
                            axisTicks: {
                                show: false,
                            },
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            axisBorder: {
                                show: false,
                            },
                            tickPlacement: 'on',
                        },
                        yaxis: {
                            tickAmount: 5,
                            labels: {
                                style: {
                                    colors: '#b9b9c3',
                                    fontSize: '0.857rem',
                                },
                                formatter(val) {
                                    return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
                                },
                            },
                        },
                        tooltip: {
                            x: {show: false},
                        },
                    },
                },
            }
        },
    }
</script>
