<template>
    <b-card no-body>
        <VueTradingView style="height: calc(100vh - 200px)"
                        v-if="market"
                        :key="$store.state.appConfig.layout.skin"
                        :options="{
                                autosize:true,
                                symbol: market,
                                timezone: 'Asia/Tehran',
                                theme: $store.state.appConfig.layout.skin==='dark'?'dark':'light',
                                style: '1',
                                locale: 'fa_IR',
                                enable_publishing: false,
                                withdateranges: true,
                                hide_side_toolbar: false,
                                allow_symbol_change: true,
                                }"/>
    </b-card>
</template>

<script>
    import {BCard} from 'bootstrap-vue'
    import VueTradingView from 'vue-trading-view';

    export default {
        name: 'TradingView',
        components: {
            BCard,
            VueTradingView,
        },
        data(){
            return {
                market : 'BINANCE:BTCUSDT'
            }
        },
        mounted() {
            if (this.$route.params.id) {
                return 'BINANCE:' + this.$route.params.id + 'USDT';
            }
        }
    }
</script>
